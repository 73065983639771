import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { jt } from 'ttag';

const ModalPrivacy = (props) => {

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <>
      <button type="button" className="btn btn-link p-0" onClick={toggle}>{jt`Privacidad`}</button>
      <Modal isOpen={modal} toggle={toggle} id="modalPrivacy" className="modal-xl">
        <ModalHeader toggle={toggle}>{jt`Política de privacidad del Sitio Web`}</ModalHeader>
        <ModalBody>
          {props.mdx}
        </ModalBody>
      </Modal>
    </>
  );
}

export default ModalPrivacy;
