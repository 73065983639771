import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`By accessing this site, you agree to be legally bound by and comply with this User Agreement. If you do not agree to comply with this User Agreement, you are not authorized to use this site. This site is not directed to children under 14, and will not knowingly collect or maintain personal information from children under 14.`}</p>
    <h3>{`Copyright`}</h3>
    <p>{`Copyright in this site and the materials included in it, unless otherwise indicated, is owned by Aula Spanish Time.`}</p>
    <p>{`Notice of claims of copyright infringement can be reached at:`}</p>
    <p>{`Attention Aula Spanish Time: Calle Hoyo, 6, 3B, 29620 Torremolinos, Málaga, Spain.
Email: `}<a parentName="p" {...{
        "href": "mailto:support@aulaspanishtime.com"
      }}>{`support@aulaspanishtime.com`}</a>{`.`}</p>
    <p>{`We respect the intellectual property of others. If you believe that your work has been copied — by us or by any third party on our site — in a manner that constitutes copyright infringement, please provide us with the written information specified below. Please note that this procedure is exclusively for notifying us that your copyrighted material has been infringed.`}</p>
    <ul>
      <li parentName="ul">{`An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest;`}</li>
      <li parentName="ul">{`A description of the copyrighted work that you claim has been infringed upon;`}</li>
      <li parentName="ul">{`A description of where the material that you claim is infringing is located on our site, including the URL of the page on which it appears;`}</li>
      <li parentName="ul">{`Your address, telephone number and e-mail address;`}</li>
      <li parentName="ul">{`A statement by you that you have a good-faith belief that the disputed use is not authorized by the copyright owner, its agent or the law; and`}</li>
      <li parentName="ul">{`A statement by you that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf.`}</li>
    </ul>
    <h3>{`Links and Frames`}</h3>
    <p>{`You may link to the home page of our site as long as the link does not cast us in a false or misleading light. You may not frame the content of our site.`}</p>
    <h3>{`Links to Other Web Sites`}</h3>
    <p>{`Our site may contain links to other websites that we think may be of interest to you. We have no control over these other sites or their content. You should be aware when you leave our site for another, and remember that other sites are governed by their own user agreements and privacy policies, which you should be sure to read.`}</p>
    <h3>{`Disclaimers and Limitation of Liability`}</h3>
    <p>{`ALTHOUGH WE TAKE REASONABLE STEPS TO PREVENT THE INTRODUCTION OF VIRUSES, WORMS, “TROJAN HORSES” OR OTHER DESTRUCTIVE MATERIALS TO OUR SITE, WE DO NOT GUARANTEE OR WARRANT THAT OUR SITE OR MATERIALS THAT MAY BE DOWNLOADED FROM OUR SITE ARE FREE FROM SUCH DESTRUCTIVE FEATURES. WE ARE NOT LIABLE FOR ANY DAMAGES OR HARM ATTRIBUTABLE TO SUCH FEATURES. WE ARE NOT LIABLE FOR ANY CLAIM, LOSS OR INJURY BASED ON ERRORS, OMISSIONS, INTERRUPTIONS OR OTHER INACCURACIES IN OUR SITE, NOR FOR ANY CLAIM, LOSS OR INJURY THAT RESULTS FROM YOUR USE OF THIS SITE OR YOUR BREACH OF ANY PROVISION OF THIS USER AGREEMENT.`}</p>
    <h3>{`Termination`}</h3>
    <p>{`We reserve the right to terminate the site and this User Agreement at any time without notice for any reason, including, in the case of the User Agreement, for your violation of any of its provisions. The Limitation of Liability and Governing Law Sections of this User Agreement shall survive any such termination.`}</p>
    <h3>{`Governing Law`}</h3>
    <p>{`This User Agreement will be governed by the laws of the territory of Spain applicable to contracts made and performed there without regard to its conflicts of law principles. You agree to submit to the exclusive jurisdiction of the courts sitting in Spanish territory and waive any jurisdictional, venue or inconvenient forum objections to such courts.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      