import React from 'react';

import AulaTimePrivacy from 'aulatime/src/components/Privacy';
import Mdx from '../legal/Privacy';

const Privacy = (props) => (
  <AulaTimePrivacy mdx={<Mdx/>} state={props.state} />
);

export default Privacy;
