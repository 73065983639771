import React, { useState } from 'react';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

const FormField = (props) => {
  const [text, setText] = useState('');
  const [placeholder, setPlaceholder] = useState(props.placeholder);

  return (
    <FormGroup>
      <Label for={props.name}>{props.label}</Label>
      <Input
        type="text"
        name={props.name}
        placeholder={placeholder}
        value={props.data.value}
        onChange={(e) => props.onChange(e.target.name, { value: e.target.value })}
        onBlur={() => {
          setPlaceholder(props.placeholder);
          setText('');
          props.validate();
        }}
        onFocus={() => {
          setPlaceholder('');
          if (!props.data.value && props.data.initialValue) {
            props.onChange(props.name, { value: props.data.initialValue })
          }
          if (!props.data.valid.valid === true) {
            setText(props.text);
          }
        }}
        {...props.data.valid}
      />
      <FormFeedback>{props.data.error}</FormFeedback>
      {text}
    </FormGroup>
  );
}

export default FormField;
