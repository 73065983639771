import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { jt } from 'ttag';

const ModalTerms = (props) => {

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <>
      <button type="button" className="btn btn-link p-0" onClick={toggle}>{jt`Términos`}</button>
      <Modal isOpen={modal} toggle={toggle} id="modalTerms" className="modal-xl">
        <ModalHeader toggle={toggle}>{jt`Aviso legal del Sitio Web`}</ModalHeader>
        <ModalBody>
          {props.mdx}
        </ModalBody>
      </Modal>
    </>
  );
}

export default ModalTerms;
