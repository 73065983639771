import React from 'react';
import { scroller } from 'react-scroll';
import { jt } from 'ttag';

const ArrowDown = (props) => {
  const scrollDown = () => {
    scroller.scrollTo(props.fragment || 'bienvenidos', {
      duration: 1500,
      delay: 0,
      smooth: "easeInOutCubic",
      offset: -103
    });
  };

  return (
    <div id="arrowDown">
      <button onClick={scrollDown} aria-label={jt`Entrar`}></button>
    </div>
  );
}

export default ArrowDown;
