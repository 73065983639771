import { t } from 'ttag';

const ValidateName = (name, data, onChange) => {
  if (! data.value) {
    onChange(name, {
      error:  t`Please enter a name`+'.',
      valid:  { invalid: true }
    });
    return false;
  }

  let regExp1 = new RegExp(/^.{3,}$/);
  if (! regExp1.test(data.value)) {
    onChange(name, {
      error:  t`Too few characters`+'.',
      valid:  { invalid: true }
    });
    return false;
  }

  let regExp2 = new RegExp(/^[A-Za-zÀ-ÖØ-öø-ÿ'. -]+$/);
  if (! regExp2.test(data.value)) {
    onChange(name, {
      error:  t`Only letters, hyphens and spaces`+'.',
      valid:  { invalid: true }
    });
    return false;
  }

  let regExp3 = new RegExp(/\s{3,}/);
  if (regExp3.test(data.value)) {
    onChange(name, {
      error:  t`Too many spaces`+'.',
      valid:  { invalid: true }
    });
    return false;
  }

  let regExp4 = new RegExp(/'{2,}/);
  if (regExp4.test(data.value)) {
    onChange(name, {
      error:  t`Too many apostrophes`+'.',
      valid:  { invalid: true }
    });
    return false;
  }

  let regExp5 = new RegExp(/-{2,}/);
  if (regExp5.test(data.value)) {
    onChange(name, {
      error:  t`Too many hyphens`+'.',
      valid:  { invalid: true }
    });
    return false;
  }

  let regExp6 = new RegExp(/[.]{2,}/);
  if (regExp6.test(data.value)) {
    onChange(name, {
      error:  t`Too many dots`+'.',
      valid:  { invalid: true }
    });
    return false;
  }

  let regExp7 = new RegExp(/.*(?:['-])$/);
  if (regExp7.test(data.value)) {
    onChange(name, {
      error:  t`Ending on a strange character`+'.',
      valid:  { invalid: true }
    });
    return false;
  }

  let regExp8 = new RegExp(/^.{3,50}$/);
  if (! regExp8.test(data.value)) {
    onChange(name, {
      error:  t`Not too many characters`+'.',
      valid:  { invalid: true }
    });
    return false;
  }

  onChange(name, {
    error:  '',
    valid:  { valid: true }
  });
  return true;
}

export default ValidateName;
