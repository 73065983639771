import React from 'react';

import ModalPrivacy from './ModalPrivacy';
import ModalTerms from './ModalTerms';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faFacebookF, faTwitter, faInstagram);

const Footer = (props) => {

  const { logo, skyline, mdxPrivacy, mdxTerms, siteMetadata } = props;
  const { title, description, social } = siteMetadata;

  return (
    <footer id="footer">
      <div className="logo">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-auto py-5">

              <img src={logo} alt={title} className="img-fluid" />

            </div>
          </div>
        </div>
      </div>

      {skyline}

      <div id="social" >
        <div className="container">
          <div className="row justify-content-center">

            <div className="col-auto py-3">
              <a href={`https://facebook.com/${social.facebook}`} target="_blank" rel="noopener noreferrer" aria-label="Facebook"><FontAwesomeIcon icon={['fab', 'facebook-f']} /></a>
            </div>

          </div>
        </div>
      </div>

      <div id="copy">
        <div className="container">
          <div className="row align-items-center">

            <div className="col-lg-auto col-12 order-lg-1 order-2 py-1 text-lg-left text-center">
              &copy; {new Date().getFullYear()} {title}. <strong>{description}</strong>.
            </div>
            <div className="col-lg col-12 legal order-lg-2 order-1 py-1 text-lg-left text-center">
              <ul className="list-inline">
                <li className="list-inline-item"><ModalPrivacy mdx={mdxPrivacy}/></li>
                <li className="list-inline-item"><ModalTerms mdx={mdxTerms}/></li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
