import { t } from 'ttag';

const ValidateTel = (name, data, onChange) => {
    if (! data.value) {
      onChange(name, {
        error:  t`Please enter a tel. number`+'.',
        valid:  { invalid: true }
      });
      return false;
    }

    let regExp1 = new RegExp(/^[0-9()-. ]+$/);
    if (! regExp1.test(data.value)) {
      onChange(name, {
        error:  t`Only digits, dashes, dots, spaces or parentheses`+'.',
        valid:  { invalid: true }
      });
      return false;
    }

    let regExp2 = new RegExp(/^\+{1}[1-9]{1}[0-9]{0,2}.*$/);
    if (! regExp2.test(data.value)) {
      onChange(name, {
        error:  t`Please prefix an international dialing code in the format +xxx, with no preceding zeros`+'.',
        valid:  { invalid: true }
      });
      return false;
    }

    let regExp5  = new RegExp(/^.{7,}$/);
    if (! regExp5.test(data.value)) {
      onChange(name, {
        error:  t`Too few digits`+'.',
        valid:  { invalid: true }
      });
      return false;
    }

    let regExp7  = new RegExp(/^.{7,35}$/);
    if (! regExp7.test(data.value)) {
      onChange(name, {
        error:  t`Too many digits`+'.',
        valid:  { invalid: true }
      });
      return false;
    }

    let regExp3 = new RegExp(/^\+{1}[1-9]{1}[0-9]{0,2}[-. ]{1,5}[0-9(]{1}[0-9()-. ]*$/);
    if (! regExp3.test(data.value)) {
      onChange(name, {
        error:  t`Please leave a space between international dialing code and tel. number`+'.',
        valid:  { invalid: true }
      });
      return false;
    }

    let regExp4 = new RegExp(/^\+{1}[1-9]{1}[0-9]{0,2}[-. ]{1,5}(?:(?:\(0{1,}\)){1}|[1-9]{1})[0-9()-. ]*$/);
    if (! regExp4.test(data.value)) {
      onChange(name, {
        error:  t`Please wrap any leading zeros in parentheses`+'.',
        valid:  { invalid: true }
      });
      return false;
    }

    onChange(name, {
      error:  '',
      valid:  { valid: true }
    });
    return true;
}

export default ValidateTel;
