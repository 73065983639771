import React, { useState, useEffect } from 'react';

import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem } from 'reactstrap';

import { Link } from 'react-scroll';
import { jt } from 'ttag';

const NavBar = (props) => {

  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);

  const menuClick = () => {
    setCollapsed(true);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropDown = (open) => {
    setDropdownOpen(dropdownOpen => !dropdownOpen);
  }

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(
    () => {
      const handleScroll = () => {
        const supportPageOffset = window.pageXOffset !== undefined;
        const isCSS1Compat = (document.compatMode || "") === "CSS1Compat";
        const posY = supportPageOffset ? window.pageYOffset : isCSS1Compat ? window.document.documentElement.scrollTop : window.document.body.scrollTop;

        setScrollPosition(posY);
      };

      window.addEventListener('scroll', handleScroll);
      //window.addEventListener("unload", () => setScrollPosition(0));

      handleScroll();

      return () => {
        window.removeEventListener('scroll', handleScroll);
        //window.removeEventListener("unload", () => setScrollPosition(0));
      }
    }, []
  );

  useEffect(
    () => {
      const navBar = document.getElementById("navBar");
      const toTop  = document.getElementById("toTop");

      if(scrollPosition > 200) {
        navBar.classList.add("nav-down");
        toTop.style.visibility = "visible";
      } else {
        navBar.classList.remove("nav-down");
        toTop.style.visibility = "hidden";
      }

    }, [scrollPosition]
  );

  useEffect(
    () => {
      const outsideClick = e => {
        const menu = document.getElementById("navbarToggler");

        if (!menu.contains(e.target)) {
          setCollapsed(true);
        }
      };

      if (!collapsed) {
        window.addEventListener('click', outsideClick);
      } else {
        window.removeEventListener('click', outsideClick);
      }

      return () => window.removeEventListener('click', outsideClick);
    }, [collapsed]
  );

  // Fixed height of Navbar
  const offset = -103;

  return (
    <>
      <Navbar light expand="false" className="fixed-top" id="navBar">
        <div className="container">

          <NavbarBrand href="/">
            {props.logo}
          </NavbarBrand>
          <div className="right">
            <div className="row no-gutters align-items-center justify-content-end">

              <div id="menuMarketing" className="col">
                {props.marketingNav}
              </div>
              {props.langBox}
              <div id="navbarToggler" className="col-auto pl-sm-3 pl-1">
                <NavbarToggler onClick={toggleNavbar} />
                <Collapse isOpen={!collapsed} id="menu">
                  {props.nav ? props.nav(menuClick, offset, dropdownOpen, toggleDropDown) :
                  <Nav navbar>
                    {props.marketingDropDown(dropdownOpen, toggleDropDown)}
                    <NavItem>
                      <Link href="#" className="nav-link" activeclassname="active" to="bienvenidos" spy={true} smooth={"easeInOutCubic"} duration={1500} offset={offset} onClick={menuClick}>
                        {jt`Bienvenidos`}
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link href="#" className="nav-link" activeclassname="active" to="cursos" spy={true} smooth={"easeInOutCubic"} duration={1500} offset={offset} onClick={menuClick}>
                        {jt`Cursos`}
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link href="#" className="nav-link" activeclassname="active" to="digital" spy={true} smooth={"easeInOutCubic"} duration={1500} offset={offset} onClick={menuClick}>
                        {jt`Online`}
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link href="#" className="nav-link" activeclassname="active" to="examenes" spy={true} smooth={"easeInOutCubic"} duration={1500} offset={offset} onClick={menuClick}>
                        {jt`Exámenes`}
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link href="#" className="nav-link" activeclassname="active" to="testimonios" spy={true} smooth={"easeInOutCubic"} duration={1500} offset={offset} onClick={menuClick}>
                        {jt`Testimonios`}
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link href="#" className="nav-link" activeclassname="active" to="contacto" spy={true} smooth={"easeInOutCubic"} duration={1500} offset={offset} onClick={menuClick}>
                        {jt`Contáctanos`}
                      </Link>
                    </NavItem>
                  </Nav>}
                </Collapse>
              </div>

            </div>
          </div>

        </div>
      </Navbar>
    </>
  );
}

export default NavBar;
