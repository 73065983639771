/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"

import "./layout.scss"

import ToTop from 'aulatime/src/components/ToTop';
import Privacy from './Privacy';

const Layout = (props) => {

  return (
    <>
      {props.children}

      <ToTop/>
      <Privacy state={props.privacyState} />
    </>
  )
}

export default Layout
