import React from 'react';

const Loading = ({ classes = 'text-primary' }) => (
  <div className="loading">
    <div className={`spinner-border ${classes}`} role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

export default Loading;
