
export const getCookieExists = (cookieName) => {

  let exists = false;
  if (document.cookie) {

    let cookies = [];
    if (document.cookie) {

      cookies = document.cookie.split(';');

      for (let cookie of cookies) {
        let name = cookie.split('=')[0].toString().trim();

        if (name === cookieName) {
          exists = true;
        }
      }

    }
  }

  return exists;
}
