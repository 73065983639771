import React from 'react';

import { animateScroll as scroll } from 'react-scroll';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronUp} from '@fortawesome/free-solid-svg-icons';

library.add(
  faChevronUp
);

const ToTop = () => {
  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 1500,
      smooth: "easeInOutCubic"
    });
  };

  return (
    <div id="toTop">
      <button className="btn" onClick={scrollToTop}>
        <FontAwesomeIcon icon="chevron-up" />
      </button>
    </div>
  );
}

export default ToTop;
