import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`We have developed our site so that you can visit it without identifying yourself or revealing any personal information to us. We do not track visitors to our site as they access other sites.`}</p>
    <p>{`We collect personally identifying data about you, such as your name and e-mail address, through our website only when you sign up for a notification, newsletter, or otherwise submit it to us voluntarily. We do not share your personal data with any third parties other than some common service providers, whose products use your information to help us improve our site or deliver notifications and newsletters. In most cases, your data remains private and cannot be shared with anyone else. However, some of these services — such as those that provide social media conveniences, and measure traffic — may place cookies on your computer. We don’t have any way of knowing how such services handle the resulting data internally.`}</p>
    <p>{`Here are the services whose cookies you can find on Aula Spanish Time:`}</p>
    <ul>
      <li parentName="ul">{`Google Analytics, which we use to measure site traffic.`}</li>
    </ul>
    <p>{`We also use SendGrid to manage newsletter, subscriber, and other identifiable user data. This service also places a cookie on your computer when you visit our website. Aula Spanish Time is the only organization that has access to the information collected by SendGrid. We use this data to improve our notifications and newsletters.`}</p>
    <p>{`Aula Spanish Time limits access to all user data for the purposes of customer service, and newsletters only. User data is not sold to or otherwise shared with anyone else outside.`}</p>
    <p>{`Remember that no data transmitted over the Internet can be guaranteed to be completely secure. You provide us with this data at your own risk. Our servers are located in the European Union. If you are located outside of the E.U., by sending us your data you consent to its transfer to and storage within the European Union.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      