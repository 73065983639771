import React from 'react';

const DividerLeft = () => (
  <div className="divider-left">
    <svg version="1.1" viewBox="0 0 100 100" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0,0 L100,100 L0,100 L0,0 Z"></path>
    </svg>
  </div>
);

export default DividerLeft;
