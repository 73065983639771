import React, { useEffect, useState } from 'react';

import { Alert } from 'reactstrap';
import { jt } from 'ttag';

import { getCookieExists } from './cookies';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';

import ModalPrivacy from './ModalPrivacy';

const Privacy = (props) => {

  const state = useState(false);
  const [visible, setVisible] = props.state || state;
  const cookiePoliticaCookies = 'politicaCookies';
  const cookieGTM = 'gatsby-gdpr-google-tagmanager';

  const onDismiss = () => {
    doGotIt();
  }

  const doGotIt = () => {
    setVisible(false);

    if (!getCookieExists(cookiePoliticaCookies)) {
      const date = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toUTCString();

      document.cookie = `${cookiePoliticaCookies}=true; Expires=${date}; Path=/; Secure; SameSite=Strict`;
      document.cookie = `${cookieGTM}=true; Expires=${date}; Path=/; Secure; SameSite=Strict`;
    }

    initializeAndTrack('/');
  }

  const doNo = () => {
    setVisible(false);

    const date = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toUTCString();
    document.cookie = `${cookiePoliticaCookies}=false; Expires=${date}; Path=/; Secure; SameSite=Strict`;
  }

  //
  useEffect(() => {

    setVisible(!getCookieExists(cookiePoliticaCookies));

  }, [setVisible]);

  return (
    <div id="privacy">

      <Alert color="dark" isOpen={visible} toggle={onDismiss} fade={false}>
        <div className="row">
          <div className="col-12">
            <p>{jt`Utilizamos cookies para ofrecerte una experiencia personalizada`}.</p>
          </div>
        </div>

        <div className="row align-items-end">
          <div className="col">
            <ModalPrivacy mdx={props.mdx}/>
          </div>
          <div className="col-auto d-flex align-items-end text-right">
            <button className="btn btn-sm btn-outline-primary" onClick={doNo}>{jt`No`}</button>&nbsp;&nbsp;
            <button className="btn btn-sm btn-primary" onClick={doGotIt}>{jt`Aceptar`}</button>
          </div>
        </div>
      </Alert>

    </div>
  );
}

export default Privacy;
