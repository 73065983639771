import React, { useState, useEffect } from 'react';

const FreshChat = (props) => {

  const [loadChat, setLoadChat] = useState(false);

  useEffect(
    () => {
      function initFreshChat() {
        window.fcWidget.init(props.config);
      }

      function initiateChat() {
        const idChat = 'freshchat-js-sdk';

        if (document.getElementById(idChat)) {
          //initFreshChat();
        } else {

          const script = document.createElement("script");

          script.id = idChat;
          script.async = true;

          document.head.appendChild(script);

          script.onload = () => {
            initFreshChat();
          }

          script.src = `//wchat.eu.freshchat.com/js/widget.js`;
        }
      }

      // Lazy load at scroll point
      const handleScroll = () => {
        const supportPageOffset = window.pageXOffset !== undefined;
        const isCSS1Compat = (document.compatMode || "") === "CSS1Compat";
        const scrollPosition = supportPageOffset ? window.pageYOffset : isCSS1Compat ? window.document.documentElement.scrollTop : window.document.body.scrollTop;

        if(scrollPosition >= 200) {
          setLoadChat(true);

          initiateChat();

          window.removeEventListener('scroll', handleScroll);
        }
      };

      if (!loadChat) {
        window.addEventListener('scroll', handleScroll);
        handleScroll();
      }

      return () => window.removeEventListener('scroll', handleScroll);
    },
    [loadChat, props.config]
  );

  return (
    <></>
  );
}

export default FreshChat;
